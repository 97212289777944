import React, { Component } from "react";
import ModalVideo from "react-modal-video";
import WOW from "wowjs";
import "../../../assets/css/animate.css";
import QuoteIcon from "../../../assets/images/icons/qoute-icon.png";



class AboutWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
        isOpen: false,
    };
}
 
  componentDidMount() {
    // animation script
    new WOW.WOW().init();
  }
  render() {
    // modal video change state
    const { isOpen } = this.state;
    return (
      <>
        {/* =============== About wrapper start =============== */}
        <div className="about-wrapper mt-96">
          <div className="container">
            <div className="about-history position-relative mt-120">
              <h3 className="float-title position-absolute">Our Story</h3>
              <div className="qoute-icon position-absolute">
                <img src={QuoteIcon}  alt="Imgs" />
              </div>
              <span dangerouslySetInnerHTML={{__html: this.props.data?.body}} />
            </div>
          </div>
        </div>
        {/* =============== About wrapper end =============== */}
        <React.Fragment>
               <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="-tJYN-eG1zk" onClose={() => this.setState({ isOpen: false })} />
           </React.Fragment>
      </>
    );
  }
}

export default AboutWrapper;
