import React, { Component } from "react";
import { Link } from "react-router-dom";
import EventIMFG1 from "../../../assets/images/event/ev-md1.png";
import "../../../assets/css/animate.css";

class EventCard extends Component {
  render() {
    const event = this.props.data;
    return (
      <div
        className="col-lg-4 col-md-6 wow fadeInUp animated"
        data-wow-delay="200ms"
        data-wow-duration="1500ms"
      >
        <div className="event-card-md">
          <div className="event-thumb">
            <img
              src={`https://managerapi.eventeam.az/images/${event.coverPhoto}`}
              alt="Imgs"
              height="300px"
              width="100%"
              style={{ objectFit: "cover" }}
            />
            <div className="event-lavels">
              {event.ticketType === "FREE" && (
                <div className="event-lavel green">
                  <span>Free</span>
                </div>
              )}
              {event.ticketType === "PAID" && (
                <div className="event-lavel yellow">
                  <span>Paid</span>
                </div>
              )}
              {event.attendanceTypeEnum === "ONLINE" && (
                <div className="event-lavel">
                  <i className="bi bi-wifi" />
                  <span>Online</span>
                </div>
              )}
            </div>
          </div>
          <div className="event-content">
            <div className="event-info">
              <div className="event-date">
                <Link onClick={this.scrollTop} to={"#"}>
                  <i className="bi bi-calendar2-week" /> {event.startDate}
                </Link>
              </div>
            </div>
            <h5 className="event-title">
              <Link
                onClick={this.scrollTop}
                to={`${process.env.PUBLIC_URL}/event-details/${event.idEvent}`}
              >
                {event.eventName}
              </Link>
            </h5>
          </div>
        </div>
      </div>
    );
  }
}

export default EventCard;
