import React, { Component } from "react";
import CountUp from "react-countup";
// page animation
import WOW from "wowjs";
import "../../../assets/css/animate.css";
// image import
import IconCalander from "../../../assets/images/icons/calander.png";
import IconPeople from "../../../assets/images/icons/people.png";
import IconSchedule from "../../../assets/images/icons/schedule.png";
import Iconspeaker from "../../../assets/images/icons/speaker.png";
import axios from "axios";

class AchiveMentCounter extends Component {
  state = {
    data: {
      events: 0,
      online: 0,
      inPerson: 0,
      users: 0
    }
  };
  
  componentDidMount(){
    new WOW.WOW().init()
    try {
      axios.get(`https://managerapi.eventeam.az/events-ms/premium-events/counts`)
      .then(res => {
        const data = res.data?.body;
        this.setState({ data });
      })
    }
    catch(e) {
      console.log(e);
    }
  }

  render() {
    return (
      <>
        {/* ===============  achievement area start  =============== */}
        <div className="achievement-area pt-120 wow fadeInLeft  animated"
                  data-wow-delay="200ms"
                  data-wow-duration="1500ms">
          <div className="achievement-overlay">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 com-sm-6">
                  <div className="single-achievement">
                    <div className="achievement-icon">
                      <i className="bi bi-calendar-heart-fill" />
                    </div>
                    <h2>
                      {" "}
                      <CountUp
                        start={0}
                        end={this.state.data.events}
                        duration={5.75}
                        className="number"></CountUp>{" "}
                    </h2>
                    <h5>Events</h5>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 com-sm-6">
                  <div className="single-achievement">
                    <div className="achievement-icon">
                      <i className="bi bi-person-square" />
                    </div>
                    <h2>
                      <CountUp
                        start={0}
                        end={this.state.data.online}
                        duration={6.75}
                        className="number">
                        600
                      </CountUp>{" "}
                    </h2>
                    <h5>Online Events</h5>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 com-sm-6">
                  <div className="single-achievement">
                    <div className="achievement-icon">
                      <i className="bi bi-person-heart" />
                    </div>
                    <h2>
                      <CountUp
                        start={0}
                        end={this.state.data.inPerson}
                        duration={7.75}
                        className="number">
                        200
                      </CountUp>
                    </h2>
                    <h5>In person events</h5>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 com-sm-6">
                  <div className="single-achievement">
                    <div className="achievement-icon">
                      <i className="bi bi-people-fill" />
                    </div>
                    <h2>
                      <CountUp
                        start={0}
                        end={this.state.data.users}
                        duration={8.75}
                        className="number">
                        300
                      </CountUp>
                    </h2>
                    <h5>Users</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  achievement area end  =============== */}
      </>
    );
  }
}

export default AchiveMentCounter;
