import axios from "axios";
import React, { Component } from "react";
import AboutWrapper from "./AboutWrapper";
import Breadcrumb from "./Breadcrumb";

class About extends Component {
  state = {
    data: null
  };
  componentDidMount(){
    axios.get(`https://managerapi.eventeam.az/properties-ms/property/key/about`)
      .then(res => {
        const data = res.data?.body;
        this.setState({ data });
      })
  }
  render() {
    return (
      <>
        {/* all about section componet */}
        <Breadcrumb title={this.state?.data?.title}  />
        {this.state.data && <AboutWrapper data={this.state.data} />}
      </>
    );
  }
}

export default About;
