import React, { Component } from "react";
import { Link } from "react-router-dom";
// page animation
import WOW from "wowjs";
import "../../../../assets/css/animate.css";
import BlogThumb from "../../../../assets/images/event/ed-thumb.png";
import GallaryIem3 from "../../../../assets/images/gallary/e-gallary-l1.png";
import GallaryIem4 from "../../../../assets/images/gallary/e-gallary-l2.png";
import GallaryIem2 from "../../../../assets/images/gallary/e-gallary-sm1.png";
import GallaryIem1 from "../../../../assets/images/gallary/e-gallary1.png";

import { SRLWrapper } from "simple-react-lightbox";

class EventDetailsLeftArea extends Component {
  componentDidMount() {
    new WOW.WOW().init();
  }
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    const data = this.props.data;

    return (
      <>
        <div className="col-xl-12">
          <div className="ed-main-wrap">
            <div className="ed-top">
              <div className="ed-thumb">
                {data?.allPhoto && (
                  <img
                    src={`https://managerapi.eventeam.az/images/${data?.allPhoto?.[0]}`}
                    alt={data?.eventName}
                  />
                )}
              </div>
              <div className="event-info row align-items-center justify-content-center mt-3">
                <div className="col-lg-2 col-md-4">
                  <div className="single-event-info">
                    <div className="info-icon">
                      <i className="bi bi-calendar2-week" />
                    </div>
                    <div className="info-content">
                      <h5>Date</h5>
                      <p>{data?.startDate}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4">
                  <div className="single-event-info">
                    <div className="info-icon">
                      <i className="bi bi-clock" />
                    </div>
                    <div className="info-content">
                      <h5>Time</h5>
                      <p>
                        {data?.startTime} &mdash; {data?.endTime}{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-4">
                  <div className="single-event-info">
                    <div className="info-icon">
                      <i className="bi bi-wifi" />
                    </div>
                    <div className="info-content">
                      <h5>Type</h5>
                      <p>{data?.attendanceTypeEnum}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4">
                  <div className="single-event-info">
                    <div className="info-icon">
                      <i className="bi bi-diagram-3" />
                    </div>
                    <div className="info-content">
                      <h5>Attendees</h5>
                      <p>
                        Min: {data?.minAttendees} / Max: {data?.maxAttendees}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4">
                  <div className="single-event-info">
                    <div className="info-icon">
                      <i className="bi bi-file-lock" />
                    </div>
                    <div className="info-content">
                      <h5>Private</h5>
                      <p> {data?.eventType === "PRIVATE" ? "Yes" : "No"} </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="event-info row align-items-center justify-content-center">
                <div className="col-lg-2 col-md-4">
                  <div className="single-event-info">
                    <div className="info-icon">
                      <i className="bi bi-blockquote-left" />
                    </div>
                    <div className="info-content">
                      <h5>Event Type</h5>
                      <p>Business, Architecture</p>
                    </div>
                  </div>
                </div>
                {data?.ticketType === "PAID" && (
                  <div className="col-lg-2 col-md-4">
                    <div className="single-event-info">
                      <div className="info-icon">
                        <i className="bi bi-wallet2" />
                      </div>
                      <div className="info-content">
                        <h5>Fee</h5>
                        <p>{data?.ticketCost} ₼</p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-lg-2 col-md-4">
                  <div className="single-event-info">
                    <div className="info-icon">
                      <i className="bi bi-person-circle" />
                    </div>
                    <div className="info-content">
                      <h5>Hosted By</h5>
                      <p>{data?.userView?.userName}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4">
                  <div className="single-event-info">
                    <div className="info-icon">
                      <i className="bi bi-geo" />
                    </div>
                    <div className="info-content">
                      <h5>{data?.address}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ed-tabs-wrapper">
              <div className="tabs-pill">
                <ul
                  className="nav nav-pills justify-content-center"
                  id="pills-tab2"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-details-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-details"
                      type="button"
                      role="tab"
                      aria-controls="pills-details"
                      aria-selected="true"
                    >
                      {" "}
                      <i className="bi bi-info-circle" /> <span>Details</span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-gallary-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-gallary"
                      type="button"
                      role="tab"
                      aria-controls="pills-gallary"
                      aria-selected="false"
                    >
                      <i className="bi bi-images" /> <span>Gallery</span>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="tab-content" id="pills-tabContent2">
                <div
                  className="tab-pane fade show active"
                  id="pills-details"
                  role="tabpanel"
                  aria-labelledby="pills-details-tab"
                >
                  <div className="details-tab-content">
                    <h3 className="ed-title">{data.eventName}</h3>
                    <p>{data.description}</p>
                    <ul className="ed-status bottom-ul">
                      <li>
                        <span>{data?.usersList?.expectedCount || 0}</span>{" "}
                        expected
                      </li>
                      <li>
                        <span>{data?.usersList?.goingCount || 0}</span> going
                      </li>
                      <li>
                        <span>{data?.usersList?.interestedCount || 0}</span>{" "}
                        interested
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-gallary"
                  role="tabpanel"
                  aria-labelledby="pills-gallary-tab"
                >
                  <div className="gallary-tab-content">
                    <SRLWrapper>
                      <div className="row">
                        {data?.allPhoto?.map((image) => (
                          <div className="col-lg-6">
                            <div className="gallary-item">
                              <img
                                src={`https://managerapi.eventeam.az/images/${image}`}
                                alt={data?.eventName}
                              />
                              <Link
                                to={`https://managerapi.eventeam.az/images/${image}`}
                              ></Link>
                            </div>
                          </div>
                        ))}
                      </div>
                    </SRLWrapper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EventDetailsLeftArea;
