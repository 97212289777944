import React, { Component } from "react";
import { Link } from "react-router-dom";

class BreadCrumb extends Component {
render () {
    return (
      <>
        {/* ===============  breadcrumb area start =============== */}
        <div className="breadcrumb-area" style={{backgroundImage: `url(${this.props.image})`}}>
          <div className="overlay-bg"></div>
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="breadcrumb-content">
                
                  <h2 className="page-title">{this.props.title}</h2>
                  <ul className="page-switcher">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/`}>
                        Home <i className="bi bi-caret-right" />
                      </Link>
                    </li>
                    <li>{this.props.title}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  breadcrumb area end =============== */}
      </>
    );
    }
}

export default BreadCrumb;
