import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
// Swiper slider imported
import SwiperCore, { Navigation } from "swiper";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/navigation/navigation.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";
import WOW from "wowjs";
import "../../../assets/css/animate.css";
// image import
import HeroIMG1 from "../../../assets/images/hero/hero-figure1.png";
import HeroIMG2 from "../../../assets/images/shapes/hero-animi.png";
import Image2 from "../../../assets/images/hero/1.png";
import Image1 from "../../../assets/images/hero/2.png";
import ImageShape from "../../../assets/images/shapes/hero-shape.png";

// install Swiper modules
SwiperCore.use([Navigation]);
class HeroArea extends Component {
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
    };
  }
  componentDidMount() {
    new WOW.WOW().init();
  }
  changeDatepickerHaldeller = (date) => {
    this.setState({ startDate: date });
  };
  render() {
    const startDate = this.state.startDate;
    const sliderInit = {
      slidesPerView: 1,
      loop: true,
      speed: 2400,
      effect: "slide",
      nav: "true",
      autoplay: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".hero-pagination",
        type: "custom",
        clickable: true,
        renderCustom: function (swiper, current, total) {
          function numberAppend(d) {
            return d < 10 ? "0" + d.toString() : d.toString();
          }
          return numberAppend(current);
        },
      },
    };
    return (
      <>
        <div className="main-slider-wrapper">
          {/* ===============  hero area start  =============== */}
          <div className="hero-area">
            <div className="hero-shape">
              <img src={ImageShape} alt="HeroIMG" />
            </div>

            <div className="container">
              <div className="swiper-container hero-slider overflow-hidden">
                <Swiper {...sliderInit} className="swiper-wrapper">
                  <SwiperSlide className="swiper-slide">
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <div className="slide-content">
                          <h2 className="text-uppercase">
                            Events, training and lots of fun
                          </h2>
                          <p className="featured-event">
                            The first event-based social network in Azerbaijan
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 text-center">
                        <div className="slide-figure  position-relative d-lg-flex justify-content-center">
                          <img
                            src={Image1}
                            alt="HeroIMG"
                            className="img-fluid"
                          />
                          <div className="animated-shape">
                            <img src={HeroIMG2} alt="HeroIMG" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <div className="slide-content">
                          <h2 className="text-uppercase">
                            Join and hurry to make new friends
                          </h2>
                          <p className="featured-event">
                            Gain new friendships from the Eventeam experience
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 text-center">
                        <div className="slide-figure  position-relative d-lg-flex justify-content-center">
                          <img
                            src={Image2}
                            alt="HeroIMG"
                            className="img-fluid"
                          />
                          <div className="animated-shape">
                            <img src={HeroIMG2} alt="HeroIMG" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
            <div className="slider-arrows text-center d-lg-block d-none">
              <div
                className="swiper-button-next"
                tabIndex={0}
                role="button"
                aria-label="Next slide"
              >
                <i className="bi bi-chevron-up" />
              </div>
              <div className="hero-pagination d-block w-auto" />
              <div
                className="swiper-button-prev"
                tabIndex={0}
                role="button"
                aria-label="Previous slide"
              >
                <i className="bi bi-chevron-down" />
              </div>
            </div>
          </div>
          {/* ===============  hero area end  =============== */}
        </div>
      </>
    );
  }
}

export default HeroArea;
