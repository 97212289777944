import axios from "axios";
import React, { Component } from "react";
import TermsWrapper from "./TermsWrapper";
import Breadcrumb from "./Breadcrumb";

class Terms extends Component {
  state = {
    data: null
  };
  componentDidMount(){
    axios.get(`https://managerapi.eventeam.az/properties-ms/property/key/terms`)
      .then(res => {
        const data = res.data?.body;
        this.setState({ data });
      })
  }
  render() {
    return (
      <>
        {/* all about section componet */}
        <Breadcrumb title={this.state?.data?.title}  />
        {this.state.data && <TermsWrapper data={this.state.data} />}
      </>
    );
  }
}

export default Terms;
