import axios from "axios";
import React, { Component } from "react";
import PrivacyWrapper from "./PrivacyWrapper";
import Breadcrumb from "./Breadcrumb";

class Privacy extends Component {
  state = {
    data: null
  };
  componentDidMount(){
    axios.get(`https://managerapi.eventeam.az/properties-ms/property/key/privacy`)
      .then(res => {
        const data = res.data?.body;
        this.setState({ data });
      })
  }
  render() {
    return (
      <>
        {/* all about section componet */}
        <Breadcrumb title={this.state?.data?.title}  />
        {this.state.data && <PrivacyWrapper data={this.state.data} />}
      </>
    );
  }
}

export default Privacy;
