import React, { Component } from "react";
// page animation
import WOW from "wowjs";
import "../../../assets/css/animate.css";
import EventCard from "../events/EventCard";
import axios from 'axios';

class PopularEventArea extends Component {
  state = {
    events: []
  };
 
  componentDidMount(){
    new WOW.WOW().init();
    axios.get(`https://managerapi.eventeam.az/events-ms/premium-events`)
      .then(res => {
        const events = res.data?.body;
        this.setState({ events });
      })
  }
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <>
        {/* ===============  Event Area start  =============== */}
        <div className="event-area">
          <div className="container position-relative pt-110">
            <div className="row">
              <div className="col-lg-12">
                <div className="background-title text-style-one">
                  <h2>Event</h2>
                </div>
                <div className="section-head">
                  <h3>Premium Events</h3>
                </div>
              </div>
            </div>
            <div className="row">
             <div className="col-lg-12">
                <div className="all-event-cards">
                <div className="row">
                  {this.state?.events?.map(event => <EventCard data={event} /> )}
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  Event Area end  =============== */}
      </>
    );
  }
}

export default PopularEventArea;
