import axios from "axios";
import React, { Component } from "react";
import BreadCrumb from "./BreadCrumb";
import EventDetailsWrapper from "./EventDetailsWrapper";

class EventDetails extends Component {
  state = {
    event: null
  };

  componentDidMount(){
    axios.get(`https://managerapi.eventeam.az/events-ms/admin/${this.props.match.params.id}`)
      .then(res => {
        const event = res.data?.body;
        this.setState({ event });
      })
  }
  render() {
    const event = this.state.event;
    return (
      <>
        <BreadCrumb title={event?.eventName} image={`https://managerapi.eventeam.az/images/${event?.coverPhoto}`} />
        {event && <EventDetailsWrapper data={event} />}
      </>
    );
  }
}

export default EventDetails;
